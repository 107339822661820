import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import OurFeatures from "../components/Index/OurFeaturesLanding"
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactFormLanding'
const Contact = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Contact" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Contact" 
            />
            <OurFeatures />
            <ContactForm />
            <Footer />
        </Layout>
    );
}

export default Contact